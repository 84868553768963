import { graphql } from 'relay-runtime';
import { createUseMutation } from '@pafcloud/relay-helpers';
import type { useJoinProgramMutation } from './__generated__/useJoinProgramMutation.graphql';

const createUseJoinProgram = createUseMutation<useJoinProgramMutation>(graphql`
  mutation useJoinProgramMutation($input: JoinProgramInput!) {
    joinProgram(input: $input) {
      ... on JoinProgramSuccess {
        success
        bonusPage {
          ...ActiveOffers_bonuses
          ...AvailablePrograms_availablePrograms
          ...QueuedOffers_bonuses
        }
        offers {
          ...turnover_offer
          offerId
          publicOfferId
          status
        }
      }
      ... on JoinProgramError {
        error
      }
    }
  }
`);

export const useJoinProgram = createUseJoinProgram((result) => {
  return result.joinProgram;
});
